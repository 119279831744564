import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
let vuexLocal = createPersistedState({
  key: 'official',
  storage: window.sessionStorage,
  reducer: (state: any) => ({
    workbook: state.workbook,
  })
})

/**通过getters取localCheckCellStorage值 不要直接取值 */
const store = createStore({
  state: {
    clientWidth: 1200,
    workbook: {},
    wxNews: [
      {
        title:'关于做好2024年度河南省工程系列建筑专业副高级职称评审和专业技术人员职业资格副高级职称考核认定申报工作的通知',
        content:"",
        created_at:'2024-10-17',
        href:"https://mp.weixin.qq.com/s/lW_t7TkRY6-q9GLnblqVdg"
      },
      {
        title:'河南住建系统职工职业技能竞赛预拌混凝土生产工决赛举行',
        content:"",
        created_at:'2024-09-30',
        href:"https://app-api.henandaily.cn/mobile/view/news/362178083379216384491408"
      },
      {
        title:'郑州市城乡建设系统预拌混凝土生产工职业技能竞赛成功举行',
        content:"",
        created_at:'2024-09-10',
        href:"https://mp.weixin.qq.com/s/8tuI3oTa6TMYWU6WWWWuVA"
      },
      {
        title:'新乡市预拌混凝土生产工技能竞赛成功举行',
        content:"",
        created_at:'2024-09-03',
        href:"https://mp.weixin.qq.com/s/YofFZRi2aPkn8WMOTmXWng"
      },
      {
        title:'关于举办2024年河南省住房和城乡建设系统职工职业技能竞赛预拌混凝土生产工决赛的通知',
        content:"",
        created_at:'2024-08-29',
        href:"https://mp.weixin.qq.com/s/i7UngPjO-5VTNHpwemL-hg"
      },
      {
        title:'河南省人力资源和社会保障厅关于印发河南省工程系列建筑专业中高级职称申报评审标准的通知',
        content:"",
        created_at:'2024-08-09',
        href:"https://mp.weixin.qq.com/s/Irt8xRIzarcy2bN3o7zb4w"
      },
      {
        title:'河南省人力资源和社会保障厅关于印发河南省工程系列专业技术人员职业资格考核认定副高级职称办法的通知',
        content:"",
        created_at:'2024-08-09',
        href:"https://mp.weixin.qq.com/s/V1C7bFSd_P5nmvGnLXCzvg"
      },

      {
        title:'关于印发2024年河南省住房和城乡建设系统职工职业技能竞赛部分工种实施方案的通知',
        content:"",
        created_at:'2024-07-18',
        href:"https://mp.weixin.qq.com/s/MXhURjjfM_s26KjVQZReeA"
      },
      {
        title:'工作动态 | 河南省智能建造观摩暨建筑业转型发展推进会在郑州召开',
        content:"",
        created_at:'2024-06-26',
        href:"https://mp.weixin.qq.com/s/S2SC1GxYn5geYJnFhMaBrQ"
      },
      {
        title:'河南省预拌混凝土企业试验实操视频：粗骨料——碎石或卵石中针状和片状颗粒的总含量试验已更新',
        content:"",
        created_at:'2024-06-13',
        href:"https://mp.weixin.qq.com/s/ZP_jIt2mfMdegYLHwIKGbg"
      },
      {
        title:'关于河南省预拌混凝土企业试验实操视频观看的通知',
        content:"",
        created_at:'2024-05-30',
        href:"https://mp.weixin.qq.com/s/JRTba44wG4k0Jp0tSwfOgA"
      },
      {
        "title": "郑州市2021年第一季度建筑材料（水泥、砂石）指导价格信息",
        "content": "",
        "created_at": "2021-05-11",
        "href": "https://mp.weixin.qq.com/s/XDz1vDKfeAdvHXBjfM48nA"
      },
      {
        "title": "2021年《预拌混凝土专项技术提升班》 开封站圆满落幕",
        "content": "",
        "created_at": "2021-05-18",
        "href": "https://mp.weixin.qq.com/s/MbOg3wK9KDcgnWS_4PaxTQ"
      },
      {
        "title": "继续教育信阳站圆满结束，下一站，将是中华文明核心发源地之许昌",
        "content": "",
        "created_at": "2021-04-7",
        "href": "https://mp.weixin.qq.com/s/l6DKM8QLC61D6BGGCfs6jQ"
      },
      {
        "title": "2021年4月份河南省混凝土生产企业试验人员新取证培训课程圆满结束！",
        "content": "",
        "created_at": "2021-04-13",
        "href": "https://mp.weixin.qq.com/s/SgXw_QBhIRwRKYFQGP39Xw"
      },
      {
        "title": "2020年12月份建筑节能、地基基础检测技术培训会议圆满落幕！",
        "content": "",
        "created_at": "2020-12-08",
        "href": "https://mp.weixin.qq.com/s/0gDQY3GdKvWVL-FRTnl9qA"
      },
    ],
    product: [
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/product/zhuti_test_2023.jpg`,
        title: "主体结构检测能力比对感谢函",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/product/environment_info_pc.png`,
        title: "室内环境企业管理系统",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/product/environment_info_weapp.png`,
        title: "室内环境企业管理小程序",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/product/environment_admin_pc.jpg`,
        title: "室内环境检测管理系统",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/product/environment_test_weapp.jpg`,
        title: "室内环境质控小程序",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/product/busin_produce_manage.png`,
        title: "预拌混凝土信息化系统",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/product/test_produce_manage.png`,
        title: "工程检测全过程管理系统",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/product/busin_neng.png`,
        title: "商混能力比对系统软件证书",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/product/busin_video.png`,
        title: "商混质控视频证书",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/product/test_video.png`,
        title: "检测质控视频证书",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/product/jieneng_video.jpg`,
        title: "建筑节能实操视频证书",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/product/neng_test_thanks.png`,
        title: "防水卷材检测能力比对感谢函",
      },
	  {
	    src: `${process.env.VUE_APP_FILE_HOST}/file/product/citystation_busin.jpg`,
	    title: "智慧平台企业系统",
	  },
	  {
	    src: `${process.env.VUE_APP_FILE_HOST}/file/product/citystation_admin.jpg`,
	    title: "智慧平台管理系统",
	  },
    {
	    src: `${process.env.VUE_APP_FILE_HOST}/file/product/neng_test.png`,
	    title: "防水卷材能力比对系统软件证书",
	  },
    {
	    src: `${process.env.VUE_APP_FILE_HOST}/file/product/citystation_report.jpg`,
	    title: "郑州市预拌混凝土质量管理信息系统研发与应用",
	  },
    ]
  },
  mutations: {
    setClientWidth(store, data) {
      store.clientWidth = data
    },
    setWorkbook(store, data) {
      store.workbook = data
    },
  },
  getters: {
  },
  actions: {
  },
  plugins: [vuexLocal]
})

export default store
